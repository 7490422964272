import { createPortal } from "react-dom";

interface Props {
  children: JSX.Element;
}

export const Portal = ({ children }: Props) => {
  if (typeof window === "undefined") return children;

  return createPortal(children, document.body);
};
