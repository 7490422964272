import { XCloseIcon } from "Icons/XClose";
import { IconType } from "Icons/types";

import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import FocusLock, { AutoFocusInside } from "react-focus-lock";

import { SerializedStyles, css } from "@emotion/react";

import { Button, Color } from "components/shared/library/Button";
import { Portal } from "components/shared/library/Portal";
import { Typography } from "components/shared/library/Typography";

import { down, isViewMobile } from "utils/mediaQueryStrings";

import { uiGray, white } from "constants/colors";

const style = {
  backdrop: css({
    width: "100%",
    height: "100%",
    position: "fixed",
    background:
      "radial-gradient(93.12% 93.12% at 50% 50%, rgba(20, 13, 53, 0.25) 0%, rgba(0, 0, 0, 0) 100%)",
    backdropFilter: "blur(4px)",
  }),
  modalWrapper: css({
    inset: 0,
    width: "100vw",
    height: "100vh",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    overflow: "auto",
  }),
  modal: css({
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    borderRadius: 24,
    background: white,
    isolation: "isolate",
    position: "relative",
    maxWidth: 640,
    width: "100%",
    [down("mobile")]: {
      width: "95%",
    },
  }),
  header: css({
    padding: 32,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [down("mobile")]: {
      padding: 16,
    },
  }),
  title: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  content: css({
    maxHeight: "75vh",
    overflow: "auto",
    padding: 32,
    "#modal-header + &": {
      // showing border only if there is a header
      borderTop: `1px solid ${uiGray.pageBackground}`,
    },
    [down("mobile")]: {
      padding: 16,
    },
  }),
  buttons: css({
    borderTop: `1px solid ${uiGray.pageBackground}`,
    display: "flex",
    gap: 12,
    padding: 32,
    [down("mobile")]: {
      flexDirection: "column-reverse",
      padding: 16,
    },
  }),
  fullWidth: css({ width: "100%" }),
};

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
  description?: React.ReactNode;
  title?: string | React.ReactNode;
  confirmLabel?: string;
  isConfirmDisabled?: boolean;
  confirmColor?: Color;

  onConfirm?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<boolean | void> | void;
  onConfirmSuccess?: () => void;
  onConfirmFail?: () => void;
  confirmLeftIcon?: IconType;
  confirmRightIcon?: IconType;
  leftButtonLabel?: string;
  leftButtonOnClick?: () => void;
  leftButtonColor?: Color;
  leftButtonIconLeft?: IconType;
  isLeftButtonDisabled?: boolean;
  isCancelable?: boolean;
  customCss?: {
    modal?: SerializedStyles;
    content?: SerializedStyles;
    footer?: SerializedStyles;
    header?: SerializedStyles;
  };
  showFooter?: boolean;
  showCloseButton?: boolean;
  confirmButtonRef?: React.RefObject<HTMLButtonElement>;
  ConfirmButton?: React.ReactNode;
};

export const Modal = ({
  description,
  isOpen,
  onClose: _onClose,
  confirmLabel = "Confirm",
  confirmColor = "purple",
  closeOnEsc = true,
  children,
  title,
  onConfirm,
  onConfirmFail,
  onConfirmSuccess,
  confirmLeftIcon,
  confirmRightIcon,
  leftButtonOnClick,
  leftButtonLabel = "Cancel",
  leftButtonColor = "black",
  leftButtonIconLeft,
  isLeftButtonDisabled = false,
  isCancelable = true,
  closeOnOverlayClick = true,
  isConfirmDisabled = false,
  customCss,
  showFooter = true,
  showCloseButton = true,
  confirmButtonRef: confirmButtonRefProp,
  ConfirmButton,
}: PropsWithChildren<Props>) => {
  const _confirmButtonRef = useRef<HTMLButtonElement>(null);
  const confirmButtonRef = confirmButtonRefProp || _confirmButtonRef;

  const modalBackdropRef = useRef<HTMLDivElement>(null);

  // This prevents the background from being scrollable while the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else if (isViewMobile()) {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  // abstract this out cause it's used in multiple places
  const onClose = useCallback(() => {
    _onClose();
  }, [_onClose]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // Enter key will trigger the confirm button if it's focused
      if (
        e.key === "Enter" &&
        confirmButtonRef.current === document.activeElement
      ) {
        confirmButtonRef.current?.click();
      }

      // This closes the modal when the user presses the escape key
      if (closeOnEsc && e.key === "Escape") {
        onClose();
      }
    };

    if (typeof window !== "undefined") {
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (typeof window !== "undefined") {
        document.removeEventListener("keydown", handleKeyPress);
      }
    };
  }, [closeOnEsc, confirmButtonRef, onClose]);

  if (!isOpen) return null;

  return (
    <>
      <Portal>
        <FocusLock>
          {/* This className is used in components/shared/library/MoreActionButton in order to ignore modals when using utils/libraryHooks/useCloseOnClickOutside */}
          <section css={style.modalWrapper} className="modal">
            <div
              ref={modalBackdropRef}
              css={style.backdrop}
              onClick={closeOnOverlayClick ? onClose : undefined}
              // This closes the modal when the user clicks on the overlay
            />
            <div
              css={css(style.modal, customCss?.modal)}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-header"
              aria-describedby="modal-body"
            >
              {title && (
                <header
                  css={css(style.header, customCss?.header)}
                  id="modal-header"
                >
                  <div css={style.title}>
                    <Typography weight="bold" color={uiGray[100]} size="XL">
                      {title}
                    </Typography>
                    {description && (
                      <Typography color={uiGray[70]} size="XS" tag="p">
                        {description}
                      </Typography>
                    )}
                  </div>
                  {showCloseButton && (
                    <Button
                      ariaLabel="Close"
                      color="gray"
                      size="small"
                      IconLeft={XCloseIcon}
                      onClick={onClose}
                    />
                  )}
                </header>
              )}
              {children && (
                <div
                  css={css(style.content, customCss?.content)}
                  id="modal-body"
                >
                  {children}
                </div>
              )}

              {showFooter && (
                <footer css={[style.buttons, customCss?.footer]}>
                  {isCancelable && (
                    <Button
                      background="outlined"
                      color={leftButtonColor}
                      onClick={leftButtonOnClick ?? onClose}
                      IconLeft={leftButtonIconLeft}
                      customCss={{
                        button: css({
                          flex: 1,
                        }),
                      }}
                      disabled={isLeftButtonDisabled}
                      isFullWidth
                    >
                      {leftButtonLabel}
                    </Button>
                  )}
                  {ConfirmButton}
                  {!ConfirmButton && onConfirm && (
                    <AutoFocusInside css={style.fullWidth}>
                      <Button
                        buttonRef={confirmButtonRef}
                        color={confirmColor}
                        onClick={onConfirm}
                        onClickFail={onConfirmFail}
                        onClickSuccess={onConfirmSuccess}
                        disabled={isConfirmDisabled}
                        IconLeft={confirmLeftIcon}
                        IconRight={confirmRightIcon}
                        isFullWidth
                      >
                        {confirmLabel}
                      </Button>
                    </AutoFocusInside>
                  )}
                </footer>
              )}
            </div>
          </section>
        </FocusLock>
      </Portal>
    </>
  );
};
