import { css } from "@emotion/react";

import { CatalogPrice } from "apis/ribbonPro";

import { Modal } from "../library/Modal";
import { BasicPlan } from "./BasicPlan";
import { ProPlan } from "./ProPlan";

const style = {
  container: css({
    display: "flex",
    gap: 24,
    flexWrap: "wrap",
    justifyContent: "center",
  }),
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  catalogPrices: CatalogPrice[];
};
export const UpgradePlanModal = ({ isOpen, onClose, catalogPrices }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Upgrade to Ribbon Pro"
      description="Your path to success is filled with unlimited possibilities."
      showFooter={false}
    >
      <div css={style.container}>
        <BasicPlan />
        <ProPlan prices={catalogPrices.filter((p) => p.productName == "pro")} />
      </div>
    </Modal>
  );
};
