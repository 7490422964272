import { unparse } from "papaparse";

import { Transaction } from "apis/earningTransaction";

import { MIN_AMOUNT_ALLOWED_FOR_REDEMPTION } from "constants/earnings";

import { getFormattedDateAtTime } from "./time";

export const getFormattedTransactionValue = (
  transaction: Transaction
): string => {
  if (transaction.transactionType === "views_incentive") {
    return `+ ${formatAmountToDollars(transaction.amountInCents)}`;
  }

  if (transaction.transactionType === "redeemed") {
    return `${formatAmountToDollars(transaction.amountInCents)}`;
  }

  if (transaction.transactionType === "paid_out") {
    return `- ${formatAmountToDollars(transaction.amountInCents)}`;
  }

  return "";
};

export const formatAmountToDollars = (amountInCents: number) => {
  return `$${(amountInCents / 100).toFixed(2)}`;
};

export const canRedeemEarnings = ({
  isVerified,
  hasStripeAccount,
  amountInCents,
}: {
  isVerified: boolean;
  hasStripeAccount: boolean;
  amountInCents: number;
}) =>
  hasStripeAccount &&
  amountInCents >= MIN_AMOUNT_ALLOWED_FOR_REDEMPTION &&
  isVerified;

export const createCsvReport = (transactions: Transaction[]): string => {
  const csvData = transactions.map((transaction) => ({
    "Amount (USD)": getFormattedTransactionValue(transaction),
    "Transaction Status": {
      earned: "Earned",
      redeemed: "Redeemed",
      paid_out: "Paid Out",
    }[transaction.transactionStatus],
    "Transaction Type": {
      views_incentive: "Views Incentive",
      redeemed: "Redeemed",
      paid_out: "Paid Out",
    }[transaction.transactionType],
    Description: {
      views_incentive: `Earned for ${transaction.typeUnit} views`,
      redeemed: "Redeemed",
      paid_out: "Paid Out",
    }[transaction.transactionType],
    Date: getFormattedDateAtTime(new Date(transaction.createdAt)),
  }));

  const csv = unparse(csvData, {
    header: true,
  });

  return csv as string;
};
