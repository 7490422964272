import { IconType } from "Icons/types";

import { SerializedStyles, css } from "@emotion/react";

import { blue, green, pink, purple, uiGray, yellow } from "constants/colors";

import { ButtonWrapper } from "./ButtonWrapper";
import { Size, Typography } from "./Typography";

const getStyle = (
  color: "blue" | "grey" | "green" | "purple" | "red" | "yellow",
  hasIconLeft: boolean
) => ({
  badge: css({
    borderRadius: 99,
    background: badgeColors[color].background,
    width: "max-content",
    display: "flex",
  }),
  small: css({
    padding: hasIconLeft ? "4px 8px 4px 4px " : "4px 8px",
  }),
  medium: css({
    padding: hasIconLeft ? "6px 16px 6px 12px " : "6px 16px",
  }),
  large: css({ padding: "8px 16px" }),
  button: css({
    "&:hover": { background: badgeColors[color].hover },
    "&:focus": {
      outline: "none",
      boxShadow: `0px 0px 0px 4px white,0px 0px 0px 8px ${purple.focusShadow}`,
    },
  }),
  notButton: css({
    background: badgeColors[color].hover,
  }),
  badgeContent: css({
    display: "flex",
    width: "max-content",
    alignItems: "center",
    gap: 4,
  }),
});

const badgeColors = {
  blue: { label: blue[700], background: blue[100], hover: blue[200] },
  grey: {
    label: uiGray[80],
    background: uiGray[10],
    hover: uiGray[20],
  },
  green: { label: green[800], background: green[100], hover: green[200] },
  purple: {
    label: purple[600],
    background: purple[100],
    hover: purple[200],
  },
  red: { label: pink[900], background: pink[100], hover: pink[200] },
  yellow: { label: yellow[900], background: "#FCF9F2", hover: "#FAF4E4" },
};

export type Props = {
  label: string;
  isCloseable?: boolean;
  color?: "blue" | "grey" | "green" | "purple" | "red" | "yellow";
  onClick?: (name: string) => void;
  name?: string;
  size?: "small" | "medium" | "large";
  customCss?: SerializedStyles;
  IconLeft?: IconType;
  IconRight?: IconType;
};

export const Badge = ({
  label,
  name,
  onClick,
  color = "grey",
  size = "small",
  customCss,
  IconLeft,
  IconRight,
}: Props) => {
  const style = getStyle(color, !!IconLeft);

  const BadgeContent = () => (
    <div css={style.badgeContent}>
      {IconLeft && (
        <IconLeft stroke={badgeColors[color].label} height={16} width={16} />
      )}
      <Typography
        color={badgeColors[color].label}
        size={{ small: "XXS", medium: "XS", large: "XL" }[size] as Size}
        weight="bold"
      >
        {label}
      </Typography>
      {IconRight && (
        <IconRight stroke={badgeColors[color].label} height={16} width={16} />
      )}
    </div>
  );
  return onClick ? (
    <ButtonWrapper
      onClick={() => onClick(name || label)}
      customCss={[style.badge, style.button, style[size], customCss]}
      ariaLabel={label}
    >
      <BadgeContent />
    </ButtonWrapper>
  ) : (
    <span css={[style.badge, style.notButton, style[size], customCss]}>
      <BadgeContent />
    </span>
  );
};
