import { keyBy } from "lodash";

import { useEffect, useRef, useState } from "react";

import { css } from "@emotion/react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Button } from "components/shared/library/Button";

import { CatalogPrice, setupStripeCheckoutSession } from "apis/ribbonPro";

import { formatAmountToDollars } from "utils/earnings";
import { getPercentageDiscount } from "utils/math";
import { getExternalPath } from "utils/urls";

import { uiGray } from "constants/colors";

import { Typography } from "../library/Typography";
import { PlanCard } from "./PlanCard";

const style = {
  hidden: css({ display: "hidden" }),
};

export const ProPlan = ({ prices }: { prices: CatalogPrice[] }) => {
  const router = useRouter();
  const { priceId } = router.query;

  const catalogByPayPeriod = keyBy(prices, "recurringInterval");
  const yearly = catalogByPayPeriod["year"];
  const monthly = catalogByPayPeriod["month"];

  // if no priceId is passed, default to monthly
  const isYearly = priceId ? priceId == yearly.stripePriceId : false;

  const payPeriodPrice = isYearly ? yearly : monthly;
  const [url, setUrl] = useState("");
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (url) linkRef?.current?.click();
  }, [url]);

  return (
    <>
      <PlanCard
        plan="pro"
        accentColor="purple"
        planTitleHelper={
          isYearly
            ? ""
            : `Save ${getPercentageDiscount(
                monthly.unitAmountDecimal,
                yearly.unitAmountDecimal / 12
              )}% billed yearly`
        }
        price={
          isYearly ? yearly.unitAmountDecimal / 12 : monthly.unitAmountDecimal
        }
        strikedPrice={isYearly ? monthly.unitAmountDecimal : undefined}
        period={payPeriodPrice.recurringInterval}
        HelperText={
          <>
            <Typography color={uiGray[70]} size="XXS">
              {"Unlimited access to all "}
            </Typography>
            <Typography color={uiGray[70]} size="XXS" weight="bold">
              Ribbon Pro
            </Typography>
            <Typography color={uiGray[70]} size="XXS">
              {" features."}
            </Typography>
          </>
        }
        // store priceId in url state so that it is contained in the return stripe checkout url
        // we don't NEED this but there are two small benefits:
        // - we can directly link to monthly or yearly default upgrade state
        // - if user refreshes the page, we won't switch them to the other plan
        onToggleChange={(val) =>
          router.push({
            query: {
              ...router.query,
              priceId: val ? yearly.stripePriceId : monthly.stripePriceId,
            },
          })
        }
        toggleChecked={isYearly}
        listTitle={"Everything in Basic, plus:"}
        featuresList={[
          "Unlimited access to Resume+",
          "Unlimited access to Copilot",
          "Unlimited access to Interview+",
          "Early access to new features and discounts",
          "Unlimited access to Chat AI",
        ]}
        ActionButton={
          <Button
            isFullWidth
            onClick={async () => {
              const { checkoutUrl } = await setupStripeCheckoutSession({
                planId: "pro",
                sourceUrl: getExternalPath(router.asPath),
                priceId: payPeriodPrice.stripePriceId,
              });
              if (checkoutUrl) setUrl(checkoutUrl);
            }}
          >
            Upgrade to Ribbon Pro
          </Button>
        }
        actionHelperText={`${formatAmountToDollars(
          payPeriodPrice.unitAmountDecimal
        )} billed ${payPeriodPrice.recurringInterval}ly`}
      />
      <Link ref={linkRef} target="_self" href={url} css={style.hidden} />
    </>
  );
};
