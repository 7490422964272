import { CheckCircleIcon } from "Icons/CheckCircle";

import { ReactNode } from "react";

import { css } from "@emotion/react";

import { UserPlan } from "apis/accountProfile";

import { formatAmountToDollars } from "utils/earnings";

import {
  red,
  ribbonProAccentColorPlanMap,
  uiGray,
  white,
} from "constants/colors";

import { Toggle } from "../library/Toggle";
import { Typography } from "../library/Typography";
import { PlanBadge } from "./PlanBadge";

const style = {
  wrapper: css({
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 24,
    borderRadius: 16,
    border: `1px solid ${uiGray[10]}`,
    background: white,
    width: 220,
  }),
  header: css({ display: "flex", flexDirection: "column", gap: 4 }),
  title: css({ display: "flex", gap: 8, alignItems: "center" }),
  price: css({ display: "flex", gap: 4, alignItems: "baseline" }),
  toggle: css({ height: 20 }),
  content: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 16,
  }),
  featureLine: css({ display: "flex", gap: 8, alignItems: "start" }),
  action: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  helperText: css({
    textAlign: "center",
    height: 16,
  }),
  toggleLabel: css({
    fontSize: 16,
    fontWeight: 700,
  }),
  divider: css({
    borderBottom: `1px solid ${uiGray[10]}`,
  }),
};

export const PlanCard = ({
  plan,
  accentColor = "grey",
  planTitleHelper,
  price,
  strikedPrice,
  period,
  HelperText,
  onToggleChange,
  toggleChecked,
  featuresList,
  listTitle,
  ActionButton,
  actionHelperText,
}: {
  plan: UserPlan;
  accentColor?: "grey" | "purple";
  planTitleHelper?: string;
  price: number;
  strikedPrice?: number;
  period?: string;
  HelperText: ReactNode | string;
  onToggleChange?: (checked: boolean) => void;
  toggleChecked?: boolean;
  featuresList: string[];
  listTitle: string;
  ActionButton: ReactNode;
  actionHelperText?: string;
}) => {
  return (
    <div css={style.wrapper}>
      <div css={style.header}>
        <div css={style.title}>
          <PlanBadge plan={plan} />
          <Typography color={red[800]} weight="bold" size="XXS">
            {planTitleHelper}
          </Typography>
        </div>
        <div css={style.price}>
          <Typography
            color={ribbonProAccentColorPlanMap[accentColor]}
            weight="bold"
            size="L"
          >
            {formatAmountToDollars(price)}
          </Typography>
          {strikedPrice && (
            <Typography color={uiGray[40]} weight="bold" size="L">
              <s>{formatAmountToDollars(strikedPrice)}</s>
            </Typography>
          )}
          <Typography color={uiGray[50]} size="XXS">
            {period ? `/ monthly` : "/ free forever"}
          </Typography>
        </div>
        <Typography tag="p" color={uiGray[70]} size="XXS">
          {HelperText}
        </Typography>
      </div>
      <div css={style.toggle}>
        {onToggleChange && (
          <Toggle
            customCss={{ label: style.toggleLabel }}
            label={"Billed yearly"}
            checked={!!toggleChecked}
            onChange={onToggleChange}
          />
        )}
      </div>
      <div css={style.divider} />
      <div css={style.content}>
        <Typography color={uiGray[90]} size="XS" weight="bold">
          {listTitle}
        </Typography>
        {featuresList.map((feature) => (
          <div css={style.featureLine} key={feature}>
            <div css={css({ flexShrink: 0 })}>
              <CheckCircleIcon
                stroke={ribbonProAccentColorPlanMap[accentColor]}
                width={16}
                height={16}
              />
            </div>
            <Typography color={uiGray[90]} size="XXS">
              {feature}
            </Typography>
          </div>
        ))}
      </div>
      <div css={style.action}>
        {ActionButton}
        <Typography color={uiGray[70]} size="XXS" customCss={style.helperText}>
          {actionHelperText}
        </Typography>
      </div>
    </div>
  );
};
