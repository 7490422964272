import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { Button, Props as ButtonProps } from "components/shared/library/Button";

import { CatalogPrice, getCatalogPricing } from "apis/ribbonPro";

import { UpgradePlanModal } from "./UpgradePlanModal";

export const UpgradePlanButton = ({
  ...buttonProps
}: Omit<ButtonProps, "onClick">) => {
  const router = useRouter();
  const { checkout } = router.query;

  // the prices will be passed to the Modal
  const [catalogPrices, setCatalogPrices] = useState<CatalogPrice[]>([]);
  // this will be used to show the modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const get = async () => {
      const data = await getCatalogPricing();
      if (data) setCatalogPrices(data.catalogPrices || []);
    };
    get();
  }, []);

  useEffect(() => {
    if (checkout == "cancel") setIsModalOpen(true);
  }, [checkout]);

  return (
    <>
      <Button
        {...buttonProps}
        disabled={!catalogPrices.length || buttonProps.disabled}
        onClick={() => setIsModalOpen(true)}
        ariaLabel="Upgrade plan"
      />
      {!!catalogPrices.length && (
        <UpgradePlanModal
          catalogPrices={catalogPrices}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};
