import { AlertCircleIcon } from "Icons/AlertCircle";

import { SerializedStyles, css } from "@emotion/react";

import {
  SLIDE_TRANSITION_DURATION,
  SLIDE_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { purple, white } from "constants/colors";
import { uiGray } from "constants/colors";

import { Tooltip } from "./Tooltip";
import { Typography } from "./Typography";

const style = {
  container: css({
    display: "flex",
    alignItems: "center",
    gap: 8,
  }),
  toggleSlider: css({
    padding: "2px",
    appearance: "none",
    width: 40,
    height: 22,
    borderRadius: 12,
    background: white,
    border: `1px solid #D8D8D8`,
    outline: "none",
    transition: `background ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    flexShrink: 0,
    "&:checked": {
      background: `linear-gradient(98.01deg, #7766FF 0%, ${purple[600]} 50%, #3E22C5 100%)`,
    },
    "&:focus": {
      outline: `2px solid #7276EF`,
    },
    "&:before": {
      content: "''",
      display: "block",
      width: 16,
      height: 16,
      borderRadius: 8,
      background: "#D8D8D8",
      transform: "translateX(2px)",
      transition: `transform ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
    "&:checked:before": {
      transform: "translateX(18px)",
      transition: `transform ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
      background: "#F1F2FD",
    },
    "&:disabled": {
      background: uiGray[10],
      border: `1px solid ${uiGray[20]}`,
      "&:before": {
        background: uiGray[20],
      },
    },
  }),
  textContainer: css({
    display: "grid",
    gridTemplateColumns: "1fr 24px",
    gap: 8,
    alignItems: "center",
  }),
  tooltipContent: css({
    display: "flex",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    padding: "7px 12px 9px 12px",
  }),
};

type Props = {
  id?: string;
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  tooltipText?: string;
  tooltipPosition?: "top" | "bottom" | "right" | "left";
  isDisabled?: boolean;
  displayLabel?: boolean;
  customCss?: {
    wrapper?: SerializedStyles;
    label?: SerializedStyles;
    textContainer?: SerializedStyles;
  };
};

export const Toggle = ({
  id,
  label,
  checked,
  onChange,
  tooltipText = "",
  tooltipPosition = "top",
  isDisabled = false,
  displayLabel = true,
  customCss,
}: Props) => {
  return (
    <div css={[style.container, customCss?.wrapper]}>
      <input
        id={id ?? `${label}-toggle`}
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        css={style.toggleSlider}
        disabled={isDisabled}
        aria-label={label}
      />
      {displayLabel && (
        <label htmlFor={id ?? `${label}-toggle`}>
          <div css={[style.textContainer, customCss?.textContainer]}>
            <Typography
              tag="span"
              color={uiGray[80]}
              weight="medium"
              size="XS"
              customCss={customCss?.label}
            >
              {label}
            </Typography>
            {tooltipText && (
              <Tooltip
                content={
                  <div css={style.tooltipContent}>
                    <Typography
                      tag="p"
                      color={uiGray[80]}
                      weight="medium"
                      size="XS"
                    >
                      {tooltipText}
                    </Typography>
                  </div>
                }
                showTooltip={true}
                position={tooltipPosition}
              >
                <AlertCircleIcon stroke={uiGray[60]} />
              </Tooltip>
            )}
          </div>
        </label>
      )}
    </div>
  );
};
