import { css } from "@emotion/react";

import { UserPlan } from "apis/accountProfile";

import { Badge, Props as BadgeProps } from "../library/Badge";

const style = {
  badge: css({ padding: "4px 8px", borderRadius: 4 }),
};

export type Props = {
  plan: UserPlan | "cancelled" | "recruit" | "free";
  size?: BadgeProps["size"];
};

export const PlanBadge = ({ plan, size = "small" }: Props) => {
  const colorMap: Record<
    UserPlan | "cancelled" | "recruit" | "free",
    BadgeProps["color"]
  > = {
    pro: "purple",
    basic: "grey",
    cancelled: "red",
    recruit: "grey",
    free: "grey",
  };

  return (
    <Badge
      label={plan.toUpperCase()}
      color={colorMap[plan]}
      customCss={style.badge}
      size={size}
    />
  );
};
