import { CheckIcon } from "Icons/Check";

import { Button } from "components/shared/library/Button";

import { PlanCard } from "./PlanCard";

export const BasicPlan = () => {
  return (
    <PlanCard
      plan="basic"
      price={0}
      HelperText={"Access to basic features and community"}
      listTitle={"Everything you need to start:"}
      featuresList={[
        "Find and connect with colleagues and classmates",
        "Request and provide verifications",
        "Save up to three searches and get weekly alerts on those searches",
      ]}
      ActionButton={
        <Button isFullWidth color="black" disabled IconLeft={CheckIcon}>
          Active
        </Button>
      }
    />
  );
};
